// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-devlog-js": () => import("./../../../src/pages/devlog.js" /* webpackChunkName: "component---src-pages-devlog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-posts-20-08-24-hello-world-md": () => import("./../../../src/posts/20-08-24-hello-world.md" /* webpackChunkName: "component---src-posts-20-08-24-hello-world-md" */),
  "component---src-posts-20-11-12-procedural-world-generation-md": () => import("./../../../src/posts/20-11-12-procedural-world-generation.md" /* webpackChunkName: "component---src-posts-20-11-12-procedural-world-generation-md" */),
  "component---src-posts-21-03-19-interesting-world-gen-md": () => import("./../../../src/posts/21-03-19-interesting-world-gen.md" /* webpackChunkName: "component---src-posts-21-03-19-interesting-world-gen-md" */),
  "component---src-posts-21-07-05-construct-3-zx-spectrum-md": () => import("./../../../src/posts/21-07-05-construct-3-zx-spectrum.md" /* webpackChunkName: "component---src-posts-21-07-05-construct-3-zx-spectrum-md" */),
  "component---src-posts-articles-md": () => import("./../../../src/posts/articles.md" /* webpackChunkName: "component---src-posts-articles-md" */),
  "component---src-posts-wiki-items-carbon-md": () => import("./../../../src/posts/wiki/items/carbon.md" /* webpackChunkName: "component---src-posts-wiki-items-carbon-md" */),
  "component---src-posts-wiki-items-circuit-md": () => import("./../../../src/posts/wiki/items/circuit.md" /* webpackChunkName: "component---src-posts-wiki-items-circuit-md" */),
  "component---src-posts-wiki-items-copper-md": () => import("./../../../src/posts/wiki/items/copper.md" /* webpackChunkName: "component---src-posts-wiki-items-copper-md" */),
  "component---src-posts-wiki-items-processor-md": () => import("./../../../src/posts/wiki/items/processor.md" /* webpackChunkName: "component---src-posts-wiki-items-processor-md" */),
  "component---src-posts-wiki-items-scrap-md": () => import("./../../../src/posts/wiki/items/scrap.md" /* webpackChunkName: "component---src-posts-wiki-items-scrap-md" */),
  "component---src-posts-wiki-items-steel-md": () => import("./../../../src/posts/wiki/items/steel.md" /* webpackChunkName: "component---src-posts-wiki-items-steel-md" */),
  "component---src-posts-wiki-items-wire-md": () => import("./../../../src/posts/wiki/items/wire.md" /* webpackChunkName: "component---src-posts-wiki-items-wire-md" */)
}

